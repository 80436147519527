import React from 'react';

import Ad, { AdTypes } from '~/components/Ad/Ad';

import StyledComponent from '~/components/Styled/Styled';
import getOr from '~/util/getOr';
import css from './MobileBannerAd.scss';

class MobileBannerAd extends React.Component<Props> {
  static displayName = 'MobileBannerAd';

  render() {
    const leagueSlug = getOr(this.props, 'leagueSlug', 'top_news');
    const articleId = getOr(this.props, 'articleId', null);
    const section = getOr(this.props, 'section', '');

    return (
      <div className={css.mobileBannerAd}>
        <Ad
          adType={AdTypes.MOBILE_BANNER}
          leagueSlug={leagueSlug}
          articleId={articleId}
          section={section}
        />
      </div>
    );
  }
}

export default StyledComponent(MobileBannerAd, [css]);
